import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import BaseLayout from "../components/global/BaseLayout";
// import Modal from "../components/Home/Modal";
import HeroSection from "../components/Home/heroSection/HeroSection_2";
import BlockContentPrincipal from "../components/block/BlockContent_1";
import CallToActionBlockTopAbout from "../components/callToAction/CallToAction_1";
import BlockContentAbout from "../components/block/BlockContent_2";
import ValuesContent from "../components/values/Values_1";
import Directories from "../components/Home/Directories";
import VideoPromocional from "../components/global/VideoPromocional";
import ServicesHome from "../components/Services/ServicesHome_1";
import CallToAction2 from "../components/callToAction/CallToAction_2";
import Map from "../components/Contact/MapContent";
import Form from "../components/Contact/Form";
import ContentServices from "../components/Services/ContentServices";
import Paletacolor from "../components/global/Paletacolor";
import GalleryContent from "../components/Gallery/GalleryContent";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        {/* <Modal /> */}

        <HeroSection

          title={rpdata?.dbSlogan?.[1]?.slogan}
          texts={rpdata?.dbSlogan?.[8]?.slogan}
          bgImg={rpdata?.gallery?.[10]}
          bgImg1={rpdata?.gallery?.[19]}
          bgImg2={rpdata?.gallery?.[4]}
          bgImg3={rpdata?.gallery?.[31]}
        />

        <BlockContentPrincipal
          sloganPrincipal
          texts={rpdata?.dbHome?.[0].text}
          images={rpdata?.gallery?.[5]}
        />

        {/* call to action va junto con el blockContent_2 */}
        <CallToActionBlockTopAbout
          title={rpdata?.dbSlogan?.[3]?.slogan}
          texts={rpdata?.dbHome?.[1].text}
          images={rpdata?.gallery?.[50]}
        />
        <BlockContentAbout
          title={"a little about us"}
          texts={rpdata?.dbAbout?.[0]?.text}
          images={rpdata?.gallery?.[0]}
          bgImages={rpdata?.gallery?.[50]}
        />

        <ValuesContent
          mission={rpdata?.dbValues?.[0].description}
          vision={rpdata?.dbValues?.[1].description}
          whychoose={rpdata?.dbValues?.[2].description}
          img1={rpdata?.gallery?.[2]}
          img2={rpdata?.gallery?.[6]}
          img3={rpdata?.gallery?.[7]}
        />

        <Directories />

        {/* video promocional */}
        {rpdata?.videosPromo?.[0]?.activo === true ? (
          <VideoPromocional
            title={rpdata?.dbSlogan?.[2]?.slogan}
            text={rpdata?.dbAbout?.[1]?.text}
            linkVideo={`${rpdata?.videosPromo?.[0].link}`}
            image={`${rpdata?.videosPromo?.[0].img}`}
          />
        ) : null}

        {/* validacion si es onepages */}
        {rpdata?.simpleWidgets?.[3]?.activo ? (
          <ContentServices />
        ) : (
          <ServicesHome />
        )}

        {rpdata?.simpleWidgets?.[3]?.activo ? (
          <div className="pt-10 pb-28">
            <h2 className="text-center pb-[50px]">
              {rpdata?.labels?.general?.titleGallery}
            </h2>
            <GalleryContent
              galleryImages={
                rpdata?.gallery?.length > 0 ? rpdata?.gallery : rpdata?.stock
              }
            />
          </div>
        ) : null}

        {rpdata?.brandingExtra?.[0]?.activo ? <Paletacolor /> : null}

        <CallToAction2
          title={rpdata?.dbSlogan?.[4]?.slogan}
          subtitle={rpdata?.dbSlogan?.[3]?.slogan}
          phone={rpdata?.dbPrincipal?.phones?.[0]?.phone}
          bgImages={rpdata?.gallery?.[0]}
        />

        {/* our reviews */}
        {rpdata?.reviews?.isHomeOnly === true ? (
          <div className="w-4/5 mx-auto py-[100px]">
            <h1 className="pb-10 text-center">
              {rpdata?.labels?.general?.titleReviews}
            </h1>
            <div className={`${rpdata?.reviews?.links?.[0]}`}></div>
          </div>
        ) : null}

        <Map
          // para cambiar el svgclas busque la clase en el archivo svgStyles
        />

        <div className="w-full flex justify-center my-20">
          <div
            className="max-w-2xl p-5 mx-5 bg-white shadow-lg "
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <h2 className="text-center">Send Us A Message</h2>
            <div className="p-4">
              <Form />
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

export default Home;
